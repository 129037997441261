import React from 'react';
import { Form, Label, Input, FormGroup, Col, Button } from 'reactstrap';
import styles from './SubscriptionForm.module.scss';

interface SubscriptionFormProps {}

export default (props: SubscriptionFormProps) => {
  const asterisk = <span className={styles.asterisk}>*</span>;
  return (
    <div id="mc_embed_signup">
      <Form
        action="https://smappee.us3.list-manage.com/subscribe/post?u=7e13594cbd18509cc193923d1&amp;id=35e12853e3"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
      >
        <div id="mc_embed_signup_scroll">
          <div className={styles.indicatesRequired}>{asterisk} indicates required</div>
          <FormGroup row>
            <Label htmlFor="mce-EMAIL" sm={4}>Email address {asterisk}</Label>
            <Col sm={8}>
              <Input type="email" name="EMAIL" className="required email" id="mce-EMAIL" />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label htmlFor="mce-FIRSTNAME" sm={4}>First name {asterisk}</Label>
            <Col sm={8}>
              <Input type="text" name="FIRSTNAME" className="required" id="mce-FIRSTNAME" />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label htmlFor="mce-LASTNAME" sm={4}>Last name {asterisk}</Label>
            <Col sm={8}>
              <Input type="text" name="LASTNAME" className="required" id="mce-LASTNAME" />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label htmlFor="mce-COUNTRY" sm={4}>Country </Label>
            <Col sm={8}>
              <Input type="text" name="COUNTRY" id="mce-COUNTRY" />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label htmlFor="mce-COMPANY" sm={4}>Company </Label>
            <Col sm={8}>
              <Input type="text" name="COMPANY" id="mce-COMPANY" />
            </Col>
          </FormGroup>
          <input type='hidden' value='2' name='group[14][2]' />
          <div id="mce-responses" className="clear">
            <div className="response" id="mce-error-response" style={{ display: 'none' }}></div>
            <div className="response" id="mce-success-response" style={{ display: 'none' }}></div>
          </div>
          {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
          <div style={{ position: 'absolute', left: -5000 }} aria-hidden="true">
            <input type="text" name="b_7e13594cbd18509cc193923d1_35e12853e3" tabIndex={-1} value="" />
          </div>
          <div className="clear" style={{ textAlign: 'center' }}>
            <Button type='submit' value="Subscribe" name="subscribe" id="mc-embedded-subscribe" color='primary' className={styles.button}>Subscribe to updates</Button>
          </div>
        </div>
      </Form>
    </div>
  );
}
