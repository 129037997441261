import React, { useState } from 'react';
import { SubsystemStatusLevel, ISubsystemStatus } from '../models/ServerStatus';
import { Alert, Tooltip } from 'reactstrap';
import { getStatusColor, getSubsystemDescription, getComponentExplanation } from '../utils/ServerComponents';
import MessageView from './MessageView';

interface StatusViewProps {
  subsystem: ISubsystemStatus;
  isAdmin: boolean;
  onCloseMessage: (id: number, timestamp: number, message: string) => void;
}
export function StatusView(props: StatusViewProps) {
  const { subsystem, isAdmin, onCloseMessage } = props;
  const { message } = subsystem;
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(open => !open);

  return (
    <Alert color={getStatusColor(subsystem.status)}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexDirection: 'row'}}>
          <div>{getSubsystemDescription(subsystem.subsystem)} <i id={`info-${subsystem.subsystem}`} className='far fa-info-circle' /></div>
          <div style={{ flexGrow: 1 }} />
          {subsystem.status === SubsystemStatusLevel.Ok && (
            <div style={{ color: '#99c031' }}>
              <i className='far fa-check' />
            </div>
          )}
          {subsystem.status === SubsystemStatusLevel.Warnings && (
            <div style={{ color: '#eb6d01' }}>
              Some issues detected <i className='far fa-exclamation-triangle' />
            </div>
          )}
          {subsystem.status === SubsystemStatusLevel.Errors && (
            <div style={{ color: '#d13b3b' }}>
              Service down <i className='far fa-ban' />
            </div>
          )}
        </div>
        {message && <MessageView message={message} isAdmin={isAdmin} onClosed={onCloseMessage} />}
      </div>
      <Tooltip placement="right" isOpen={isTooltipOpen} target={`info-${subsystem.subsystem}`} toggle={toggleTooltip}>
        {getComponentExplanation(subsystem.subsystem)}
      </Tooltip>
    </Alert>
  );
}
