export const enum Subsystem {
  EVLine = 'evline',
  EVLineOCPP = 'evline-ocpp',
  EVLineCSMS = 'evline-csms',
  Cloud = 'cloud',
  Historical = 'historical',
  LiveData = 'livedata',
  API = 'api',
  Dashboard = 'dashboard',
  Website = 'website',
  Android = 'android',
  IOS = 'ios',

  Global = 'global'
}

export const Subsystems: Subsystem[] = [
  Subsystem.Website,
  Subsystem.Dashboard,
  Subsystem.Android,
  Subsystem.IOS,
  Subsystem.API,
  Subsystem.Cloud,
  Subsystem.LiveData,
  Subsystem.Historical,
  Subsystem.EVLine,
  Subsystem.EVLineOCPP,
  Subsystem.EVLineCSMS,
  
  Subsystem.Global
];
