import React from 'react';
import { Alert } from 'reactstrap';
import { IMessage } from '../models/Message';
import MessageView from './MessageView';

function getStatusDescription(status: 'error'|'warning'|'success') {
  if (status === 'error')
    return 'One or more services are down';
  else if (status === 'warning')
    return 'Some issues have been detected';
  else
    return 'All systems operational';
}

interface OverallStatusViewProps {
  status: 'error'|'warning'|'success';
  message?: IMessage;
  isAdmin: boolean;
  onMessageClosed: (id: number, timestamp: number, message: string) => void;
}
export function OverallStatusView(props: OverallStatusViewProps) {
  const { status, message, isAdmin, onMessageClosed } = props;

  return (
    <Alert color={status + '-inverted'} style={{ marginBottom: '2em' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {status === 'success'&& <div style={{ paddingRight: '0.25rem' }}><i className='far fa-check' /></div>}
        {status === 'warning' && <div style={{ paddingRight: '0.25rem' }}><i className='far fa-exclamation-triangle' /></div>}
        {status === 'error' && <div style={{ paddingRight: '0.25rem' }}><i className='far fa-ban' /></div>}
        <div style={{ width: '100%', flexGrow: 1 }}>{message ? (
          <MessageView message={message} isAdmin={isAdmin} onClosed={onMessageClosed} />
         ) : getStatusDescription(status)}</div>
        <div style={{ flexGrow: 1 }} />
      </div>
    </Alert>
  );
}
