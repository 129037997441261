import React, { useState } from 'react';
import { ModalFooter } from 'reactstrap';
import { onMarketingCookiesEnabled } from '../utils/GoogleAnalytics';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import styles from './CookieNagger.module.scss';
import { classes } from '../utils/style';

interface ICookieSettings {
  accepted: boolean;
  allowMarketing: boolean;
}

export function getCookieSettings(): ICookieSettings {
  const settingsString = localStorage.getItem('cookies') || '{}';
  return { accepted: false, allowMarketing: false, ...JSON.parse(settingsString) };
}

function updateSettings(settings: ICookieSettings) {
  localStorage.setItem('cookies', JSON.stringify(settings));

  if (settings.allowMarketing) {
    onMarketingCookiesEnabled();
  }
}

export function CookieNagger() {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalResolve, setModalResolve] = useState<(settings: ICookieSettings|undefined) => void>();
  const [settings, setSettings] = useState(getCookieSettings());

  const handleClickedAccept = () => {
    const settings: ICookieSettings = { accepted: true, allowMarketing: true };
    setSettings(settings);
    updateSettings(settings);
  };

  const handleClickedSettings = async () => {
    const settings = await new Promise<ICookieSettings|undefined>(resolve => {
      setModalResolve(() => (response: ICookieSettings|undefined) => {
        setModalVisible(false);
        resolve(response);
      });
      setModalVisible(true);
    });
    
    if (!settings)
      return;
    
    setSettings(settings);
    updateSettings(settings);
  };

  return settings.accepted ? null : (
    <div className={styles.main}>
      <p style={{ margin: 0 }}>
        This website uses cookies to improve your experience. We'll assume you're ok with this, but you can opt-out if you wish.
        <br />
        <a href="https://www.smappee.com/privacy/" className={styles.link}>Smappee privacy policy</a>
        &nbsp;-&nbsp;
        <a href="https://www.smappee.com/cookie/" className={styles.link}>cookie policy</a>
      </p>
      <Button color='link' onClick={handleClickedSettings} className={styles.link}>Cookie settings</Button>
      <Button color='primary' onClick={handleClickedAccept} className={styles.button}>Accept</Button>
      {modalResolve !== undefined && <CookieSettingsModal isOpen={modalVisible} resolve={modalResolve} />}
    </div>
  );
}

interface CookieSettingsModalProps {
  isOpen: boolean;
  resolve: (settings: ICookieSettings|undefined) => void;  
}

function CookieSettingsModal(props: CookieSettingsModalProps) {
  const { isOpen, resolve } = props;
  const handleClose = () => resolve(undefined);

  const [necessaryOpen, setNecessaryOpen] = useState(false);
  const [marketingOpen, setMarketingOpen] = useState(false);

  const handleToggleNecessary = () => setNecessaryOpen(!necessaryOpen);
  const handleToggleMarketing = () => setMarketingOpen(!marketingOpen);

  const [marketingEnabled, setMarketingEnabled] = useState(true);

  const handleMarketingChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMarketingEnabled(e.currentTarget.checked);
  };

  const handleClickedSave = () => {
    resolve({
      accepted: true,
      allowMarketing: marketingEnabled
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={handleClose}
      size="lg"
      autoFocus={false}
    >
      <ModalHeader toggle={handleClose}>
        Privacy Overview
      </ModalHeader>
      <ModalBody>
        <p style={{ marginTop: '1rem' }}>
          This website uses cookies to improve your experience while you navigate through the website. Out of these cookies, the cookies that are categorized as necessary are stored on your browser as they are essential for the working of basic functionalities of the website. We also use third-party cookies that help us analyze and understand how you use this website. These cookies will be stored in your browser only with your consent. You also have the option to opt-out of these cookies. But opting out of some of these cookies may have an effect on your browsing experience.
        </p>
        <div className="cli-col-12 cli-align-items-stretch cli-px-0 cli-tab-section-container">
          <div className="cli-tab-section">
            <div className={classes('cli-tab-header', necessaryOpen ? 'cli-tab-active' : '')} onClick={handleToggleNecessary}>
              <a role="button" tabIndex={0} className="cli-nav-link cli-settings-mobile">
                Necessary
              </a>
              <div className="wt-cli-necessary-checkbox">
                <input type="checkbox" className="cli-user-preference-checkbox" id="wt-cli-checkbox-necessary" data-id="checkbox-necessary" checked={true} />
                <label className="form-check-label" htmlFor="wt-cli-checkbox-necessary">Necessary</label>
              </div>
              <span className="cli-necessary-caption">Always Enabled</span>
            </div>
            <div className="cli-tab-content" style={{ display: necessaryOpen ? 'block' : 'none' }}>
              <div className="cli-tab-pane cli-fade" data-id="necessary">
                <p>Necessary cookies are absolutely essential for the website to function properly. This category only includes cookies that ensures basic functionalities and security features of the website. These cookies do not store any personal information.</p>
              </div>
            </div>
          </div>
          <div className="cli-tab-section">
            <div className={classes('cli-tab-header', marketingOpen ? 'cli-tab-active' : '')} onClick={handleToggleMarketing}>
              <a role="button" tabIndex={0} className="cli-nav-link cli-settings-mobile">
                Non-necessary
              </a>
              <div className="cli-switch">
                <input
                  type="checkbox"
                  id="wt-cli-checkbox-non-necessary"
                  className="cli-user-preference-checkbox"
                  data-id="checkbox-non-necessary"
                  checked={marketingEnabled}
                  onChange={handleMarketingChanged}
                />
                <label
                  htmlFor="wt-cli-checkbox-non-necessary"
                  className="cli-slider"
                  data-cli-enable="Enabled"
                  data-cli-disable="Disabled"
                >
                  <span className="wt-cli-sr-only">Non-necessary</span>
                </label>
              </div>
            </div>
            <div className="cli-tab-content" style={{ display: marketingOpen ? 'block' : 'none' }}>
              <div className="cli-tab-pane cli-fade" data-id="non-necessary">
                <p>Any cookies that may not be particularly necessary for the website to function and is used specifically to collect user personal data via analytics, ads, other embedded contents are termed as non-necessary cookies. It is mandatory to procure user consent prior to running these cookies on your website.</p>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color='primary' onClick={handleClickedSave} className={styles.button}>Save</Button>
      </ModalFooter>
    </Modal>
  );
}
