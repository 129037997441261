import React, { useState } from "react";
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader } from "reactstrap";
import { ILoginState } from "../models/LoginState";
import styles from './SubscriptionForm.module.scss';

interface LoginButtonProps {
  state: ILoginState;
  onLogin: (token: string) => void;
  onLogout: () => void;
}

export default function LoginButton(props: LoginButtonProps) {
  const { state, onLogin } = props;
  const [isOpen, setOpen] = useState(false);
  const [password, setPassword] = useState('');

  const handleClicked = () => setOpen(true);
  const handleClosed = () => setOpen(false);

  const handleClickedLogin = () => {
      fetch('/adminlogin', {
        method: 'POST',
        body: JSON.stringify({ password }),
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(response => response.json())
        .then(response => {
          const token = response.token;
          onLogin(token);
          setOpen(false);
        });
  };
  
  return <>
    {state.token === undefined && (
      <Button onClick={handleClicked} color='primary' className={styles.button} style={{ marginRight: '1em' }}>Login</Button>
    )}
    {state.token !== undefined && (
      <Button onClick={props.onLogout} color='secondary' className={styles.button} style={{ marginRight: '1em' }}>Logout</Button>
    )}
    <Modal isOpen={isOpen} toggle={handleClosed}>
      <ModalHeader toggle={handleClosed}>Login to admin panel</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup row>
            <Label htmlFor="password" sm={4}>Password</Label>
            <Col sm={8}>
              <Input
                type="password"
                name="password"
                className="required"
                id="password"
                value={password}
                onChange={e => setPassword(e.currentTarget.value)}
              />
            </Col>
          </FormGroup>
          <div className="clear" style={{ textAlign: 'center' }}>
            <Button className={styles.button} onClick={handleClickedLogin}>Login</Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  </>;
}
