import React, { useState, useEffect } from 'react';
import { IServerStatus, SubsystemStatusLevel } from './models/ServerStatus';
import logo from './assets/smappee-logo.png';
import { OverallStatusView } from './components/OverallStatusView';
import { StatusView } from './components/StatusView';
import { IncidentHistoryView } from './components/IncidentHistoryView';
import SubscriptionButton from './components/SubscriptionButton';
import Footer from './components/Footer';
import { CookieNagger } from './components/CookieNagger';
import LoginButton from './components/LoginButton';
import { ILoginState } from './models/LoginState';
import { Subsystem } from './models/Subsystem';
import PostMessageButton from './components/PostMessageButton';
import { IMessage } from './models/Message';

function getOverallStatus(status: IServerStatus): { status: 'error'|'warning'|'success', message?: IMessage } {
  const global = status.subsystems.find(x => x.subsystem === Subsystem.Global);
  const message = global && global.message;

  if (global && global.status === SubsystemStatusLevel.Errors)
    return { status: 'error', message };
  if (status.subsystems.some(c => c.status === SubsystemStatusLevel.Errors))
    return { status: 'error', message };
  
  if (global && global.status === SubsystemStatusLevel.Warnings)
    return { status: 'warning', message };
  if (status.subsystems.some(c => c.status === SubsystemStatusLevel.Warnings))
    return { status: 'warning', message };
  
  return {status: 'success', message };
}

function App() {
  const [status, setStatus] = useState<IServerStatus>();
  useEffect(() => {
    fetch('/status')
      .then(response => response.json())
      .then(setStatus);
  }, []);

  const [loginState, setLoginState] = useState<ILoginState>({
    token: window.localStorage.getItem('admin-token') || undefined
  });

  const handleLogin = (token: string) => {
    setLoginState({ token });
    window.localStorage.setItem('admin-token', token);
  };

  const handleLogout = () => {
    setLoginState({ token: undefined });
    window.localStorage.removeItem('admin-token');
  };

  const handleRefresh = () => {
    fetch('/status')
      .then(response => response.json())
      .then(setStatus);
  };

  const handleCloseMessage = (id: number, timestamp: number, message: string) => {
    fetch(`/message/${id}/close`, {
      method: 'POST',
      body: JSON.stringify({
        token: loginState.token,
        timestamp,
        message
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(() => handleRefresh());
  };

  const overallStatus = status && getOverallStatus(status);
  const isAdmin = window.location.search.includes('admin');
  const hasAdminRights = isAdmin && loginState.token !== undefined;

  return <>
    <div className='container main-container'>
      <div style={{ position: 'absolute', right: 15, top: 0}}>
        {hasAdminRights && loginState.token !== undefined && (
          <PostMessageButton token={loginState.token} refresh={handleRefresh} />
        )}
        {isAdmin && (
          <LoginButton state={loginState} onLogin={handleLogin} onLogout={handleLogout} />
        )}
        <SubscriptionButton />
      </div>
      <img src={logo} className='smappee-logo' />
      {overallStatus !== undefined && (
        <OverallStatusView
          status={overallStatus.status}
          message={overallStatus.message}
          isAdmin={hasAdminRights}
          onMessageClosed={handleCloseMessage}
        />
      )}
      {status === undefined && (
        <p style={{ textAlign: 'center'}}><i className='far fa-spin fa-circle-notch' /><br />Loading...</p>
      )}
      {status !== undefined && (
        <div>
          {status.subsystems.filter(x => x.subsystem !== Subsystem.Global).map(subsystem => (
            <StatusView key={subsystem.subsystem} subsystem={subsystem} isAdmin={hasAdminRights} onCloseMessage={handleCloseMessage} />
          ))}
        </div>
      )}
      {status && <IncidentHistoryView incidents={status.incidents} />}
    </div>
    <CookieNagger />
    <Footer />
  </>;
}

export default App;
