import React, { useState } from 'react';
import dayjs from 'dayjs';
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { IMessage } from '../models/Message';
import styles from './SubscriptionForm.module.scss';

interface CloseMessageDialogProps {
  isOpen: boolean;
  message: IMessage;
  onClose: () => void;
  onConfirm: (timestamp: number, message: string) => void;
}

export default function CloseMessageDialog(props: CloseMessageDialogProps) {
  const {
    isOpen,
    message,
    onClose,
    onConfirm
  } = props;

  const [resolutionMessage, setResolutionMessage] = useState('');
  const [resolutionDate, setResolutionDate] = useState(dayjs().format('YYYY-MM-DD HH:mm'));

  const handleClickedSubmit = () => {
    const timestamp = dayjs(resolutionDate, 'YYYY-MM-DD HH:mm');
    onConfirm(timestamp.valueOf(), resolutionMessage);
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <ModalHeader toggle={onClose}>Post status message</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup row>
            <Label htmlFor="timestamp" sm={4}>Timestamp</Label>
            <Col sm={8}>
              <Input
                name="timestamp"
                id="timestamp"
                value={resolutionDate}
                onChange={e => setResolutionDate(e.currentTarget.value)}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label htmlFor="message" sm={4}>Message</Label>
            <Col sm={8}>
              <Input
                type="textarea"
                name="message"
                id="message"
                rows={4}
                value={resolutionMessage}
                onChange={e => setResolutionMessage(e.currentTarget.value)}
              />
            </Col>
          </FormGroup>
          <div className="clear" style={{ textAlign: 'center' }}>
            <Button className={styles.button} onClick={handleClickedSubmit}>Close incident</Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  )
}
