import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import SubscriptionForm from './SubscriptionForm';
import styles from './SubscriptionForm.module.scss';

interface SubscriptionButtonProps {}

export default (props: SubscriptionButtonProps) => {
  const [isOpen, setOpen] = useState(false);

  const handleClicked = () => setOpen(true);
  const handleClosed = () => setOpen(false);
  
  return <>
    <Button onClick={handleClicked} color='primary' className={styles.button}>Subscribe to server updates</Button>
    <Modal isOpen={isOpen} toggle={handleClosed}>
      <ModalHeader toggle={handleClosed}>Subscribe to server updates</ModalHeader>
      <ModalBody>
        <SubscriptionForm />
      </ModalBody>
    </Modal>
  </>;
}
