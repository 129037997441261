import * as commonmark from 'commonmark';
import React, { useMemo, useState } from 'react';
import { Button } from 'reactstrap';
import { IMessage } from '../models/Message';
import { SubsystemStatusLevel } from '../models/ServerStatus';
import CloseMessageDialog from './CloseMessageDialog';
import styles from './MessageView.module.scss';

interface MessageViewProps {
  message: IMessage;
  isAdmin?: boolean;
  onClosed?: (id: number, timestamp: number, message: string) => void;
}
export default function MessageView(props: MessageViewProps) {
  const { isAdmin = false, onClosed } = props;
  const { id, message, level, resolution_message } = props.message;
  const [closeOpened, setCloseOpened] = useState(false);
  
  const html = useMemo(() => {
    const parser = new commonmark.Parser();
    const renderer = new commonmark.HtmlRenderer();
    const parsed = parser.parse(message);
    return renderer.render(parsed);
  }, [message]);
  
  return <div>
    {isAdmin && onClosed && (
      <Button
        style={{ float: 'right', position: 'relative', padding: 0 }}
        color='link'
        title='close'
        onClick={() => setCloseOpened(true)}
      >
        <i className='far fa-check' />
      </Button>
    )}
    <div dangerouslySetInnerHTML={{__html: html}} className={styles.text} />
    {resolution_message && <div className={styles.resolution}>{resolution_message}</div>}
    {closeOpened && onClosed && (
      <CloseMessageDialog
        isOpen={closeOpened}
        message={props.message}
        onClose={() => setCloseOpened(false)}
        onConfirm={(timestamp, message) => onClosed(id, timestamp, message)}
      />
    )}
  </div>;
}
