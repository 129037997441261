import React, { useState } from 'react';
import { Button, Col, Form, FormGroup, FormText, Input, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { SubsystemStatusLevel } from '../models/ServerStatus';
import { Subsystem, Subsystems } from '../models/Subsystem';
import { getSubsystemDescription } from '../utils/ServerComponents';
import styles from './SubscriptionForm.module.scss';

interface PostMessageButtonProps {
  token: string;
  refresh: () => void;
}

export default function PostMessageButton(props: PostMessageButtonProps) {
  const { token } = props;

  const [isOpen, setOpen] = useState(false);
  const [subsystem, setSubsystem] = useState(Subsystem.Global);
  const [level, setLevel] = useState(SubsystemStatusLevel.Errors);
  const [message, setMessage] = useState('');

  const handleClicked = () => setOpen(true);
  const handleClosed = () => setOpen(false);

  const handleClickedSubmit = () => {
      fetch('/message', {
        method: 'POST',
        body: JSON.stringify({ token, level, subsystem, message }),
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(response => response.json())
        .then(response => {
          setOpen(false);
          props.refresh();
        });
  };
  
  return <>
    <Button onClick={handleClicked} color='secondary' className={styles.button} style={{ marginRight: '1em' }}>Post message</Button>
    <Modal isOpen={isOpen} toggle={handleClosed}>
      <ModalHeader toggle={handleClosed}>Post status message</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup row>
            <Label htmlFor="subsystem" sm={4}>Subsystem</Label>
            <Col sm={8}>
              <Input
                type="select"
                name="subsystem"
                id="subsystem"
                value={subsystem}
                onChange={e => setSubsystem(e.currentTarget.value as Subsystem)}
              >
                {Subsystems.map(subsystem => (
                  <option key={subsystem} value={subsystem}>
                    {getSubsystemDescription(subsystem)}
                  </option>
                ))}
              </Input>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={4}>Status</Label>
            <Col sm={8}>
              <FormGroup check>
                <Label check>
                  <Input
                    type='radio'
                    name='status-error'
                    checked={level === SubsystemStatusLevel.Errors}
                    onChange={e => e.currentTarget.checked && setLevel(SubsystemStatusLevel.Errors)}
                  />{' '}
                  Error - system down
                </Label>
                <Label check>
                  <Input
                    type='radio'
                    name='status-warning'
                    checked={level === SubsystemStatusLevel.Warnings}
                    onChange={e => e.currentTarget.checked && setLevel(SubsystemStatusLevel.Warnings)}
                  />{' '}
                  Warning - degraded performance
                </Label>
                <Label check>
                  <Input
                    type='radio'
                    name='status-info'
                    checked={level === SubsystemStatusLevel.Ok}
                    onChange={e => e.currentTarget.checked && setLevel(SubsystemStatusLevel.Ok)}
                  />{' '}
                  OK - informational message
                </Label>
              </FormGroup>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label htmlFor="message" sm={4}>Message</Label>
            <Col sm={8}>
              <Input
                type="textarea"
                name="message"
                id="message"
                rows={4}
                value={message}
                onChange={e => setMessage(e.currentTarget.value)}
              />
              <FormText>
                Use <a href="https://commonmark.org/help/" target="_blank">markdown</a> for formatting
              </FormText>
            </Col>
          </FormGroup>
          <div className="clear" style={{ textAlign: 'center' }}>
            <Button className={styles.button} onClick={handleClickedSubmit}>Submit</Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  </>;
}
