import React, { useMemo } from 'react';
import { IIncident, SubsystemStatusLevel } from '../models/ServerStatus';
import { getSubsystemDescription } from '../utils/ServerComponents';
import dayjs from 'dayjs';
import styles from './IncidentHistoryView.module.scss';
import MarkdownText from './MessageView';

interface IncidentHistoryViewProps {
  incidents: IIncident[];
}

interface IncidentsForDay {
  timestamp: dayjs.Dayjs;
  incidents: IIncident[];
}

function groupByDate(incidents: IIncident[]): IncidentsForDay[] {
  const now = +new Date();
  let timestamp = dayjs(now - 14 * 24 * 3600 * 1000).startOf('day');
  const result: IncidentsForDay[] = [];
  while (timestamp.valueOf() < now) {
    const next = timestamp.add(1, 'days');
    const timestampMillis = timestamp.valueOf();
    const nextMillis = next.valueOf();

    const incidentsForToday = incidents.filter(x => x.timestamp >= timestampMillis && x.timestamp < nextMillis);
    result.push({ timestamp, incidents: incidentsForToday });
    timestamp = next;
  }
  return result;
}

export function IncidentHistoryView(props: IncidentHistoryViewProps) {
  const { incidents } = props;
  const grouped = useMemo(() => {
    return groupByDate(incidents).reverse().map(incidentsForDay => {
      const incidents = incidentsForDay.incidents;
      const date = incidentsForDay.timestamp.format('D MMMM');
      return (
        <div key={date}>
          <h3 className={styles.dayHeader}>{date}</h3>
          {incidents.length === 0 ? (
            <p className={styles.noIncidents}>No incidents reported.</p>
          ) : (
            <ul>
              {incidents.map(incident => (
                <li key={incident.timestamp} className={incident.level === SubsystemStatusLevel.Warnings ? styles.warning : undefined}>
                  {dayjs(incident.timestamp).format('HH:mm') + ': ' + getSubsystemDescription(incident.subsystem)} {incident.level === SubsystemStatusLevel.Warnings ? '- degraded performance' : 'outage'}
                  {incident.resolved && <IncidentResolvedView incident={incident} />}
                  {incident.message && <>
                    <MarkdownText message={incident.message} />
                  </>}
                </li>
              ))}
            </ul>
          )}
        </div>
      );
    });
  }, [incidents]);
  return (
    <div style={{ marginTop: '3em' }}>
      <h2>Past Incidents</h2>
      {grouped}
    </div>
  );
}

interface IncidentResolvedViewProps {
  incident: IIncident;
}

function IncidentResolvedView(props: IncidentResolvedViewProps) {
  const { incident } = props;
  const incidentDate = dayjs(incident.timestamp).format('DD/MM/yyyy');
  const resolvedDate = incident.resolvedTimestamp === undefined ? undefined : dayjs(incident.resolvedTimestamp).format('DD/MM/yyyy');
  const resolvedTimestamp = incident.resolvedTimestamp === undefined ? '' : (
    resolvedDate !== incidentDate ? dayjs(incident.resolvedTimestamp).format('DD MMM HH:mm') : dayjs(incident.resolvedTimestamp).format('HH:mm')
  );
  return (
    <span className={styles.resolved}>
      &nbsp;&middot; this incident has been resolved
      {resolvedTimestamp && ' at ' + resolvedTimestamp}
    </span>
  );
}
