import { SubsystemStatusLevel } from "../models/ServerStatus";
import { Subsystem } from "../models/Subsystem";

export function getStatusColor(status: SubsystemStatusLevel): 'success'|'danger'|'warning' {
  switch (status) {
    case SubsystemStatusLevel.Ok:
      return 'success';
    case SubsystemStatusLevel.Warnings:
      return 'warning';
    case SubsystemStatusLevel.Errors:
      return 'danger';
    default:
      return 'danger';
  }
}

export function getSubsystemDescription(component: Subsystem) {
  switch (component) {
    case Subsystem.Android: return 'Android app';
    case Subsystem.API: return 'API';
    case Subsystem.Cloud: return 'Cloud';
    case Subsystem.Dashboard: return 'Dashboard';
    case Subsystem.Historical: return 'Historical data';
    case Subsystem.IOS: return 'iOS app';
    case Subsystem.LiveData: return 'Live data';
    case Subsystem.Website: return 'Website';
    case Subsystem.EVLine: return 'Smappee EV Line';
    case Subsystem.EVLineOCPP: return 'Smappee EV Line OCPP';
    case Subsystem.EVLineCSMS: return 'Smappee EV Line CSMS';
    case Subsystem.Global: return '(Global)';
    default: return component;
  }
}

export function getComponentExplanation(component: Subsystem) {
  switch (component) {
    case Subsystem.API: return 'Allows external applications to communicate with our systems';
    case Subsystem.Cloud: return 'Performs handling of mobile app and dashboard requests, as well as processing of incoming data';
    case Subsystem.Dashboard: return 'Web interface enabling users to view their data and adjust their configuration';
    case Subsystem.Historical: return 'This system is responsible for receiving and storing historical data';
    case Subsystem.LiveData: return 'Processes live data for display in the bubbles and live views. Also responsible for processing Connect series data';
    case Subsystem.Website: return 'The smappee.com website';
    case Subsystem.EVLine: return 'Provides overload protection and smart charging functionality';
    case Subsystem.EVLineOCPP: return 'OCPP communication for EV Line devices. Required for charging using external charging cards';
    case Subsystem.EVLineCSMS: return 'EV Line payments for EV Line device. Required to process charging sessions with the Smappee CSMS';
    default: return '';
  }
}
