import { IMessage } from "./Message";
import { Subsystem } from "./Subsystem";

export interface IServerStatus {
  subsystems: ISubsystemStatus[];
  incidents: IIncident[];
}

export interface IIncident {
  subsystem: Subsystem;
  timestamp: number;
  resolved: boolean;
  resolvedTimestamp?: number;
  message?: IMessage;
  level: SubsystemStatusLevel;

  recoveryEventId?: string;
}

export enum SubsystemStatusLevel {
  Ok = 'ok',
  Warnings = 'warnings',
  Errors = 'errors'
}

export interface ISubsystemStatus {
  subsystem: Subsystem;
  status: SubsystemStatusLevel;
  message?: IMessage;
}
