import React from 'react';
import dayjs from 'dayjs';
import styles from './Footer.module.scss';
import { version } from '../../package.json';

export default (props: {}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper2}>
        <div className={styles.footer}>
          <span>© {dayjs().year()} Smappee. All rights reserved.</span>
          <ul id="menu-legal-en" className="nav">
            <li><a href="https://www.smappee.com/conditions/">terms</a></li>
            <li><a href="https://www.smappee.com/returns/">returns</a></li>
            <li><a href="https://www.smappee.com/privacy/">smappee privacy policy</a></li>
            <li><a href="https://www.smappee.com/cookie/">cookie policy</a></li>
            <li><a href="https://www.smappee.com/license/">license</a></li>
            <li><a href="https://www.smappee.com/disclaimer/">disclaimer</a></li>
          </ul>
        </div>
      </div>
      <div className={styles.footer2}>
        Designed &amp; manufactured with ♥ in Europe
        &nbsp;
        <span style={{ color: '#155a55', position: 'absolute', right: 10 }}>{version}</span>
      </div>
    </div>
  );
}
